import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import moment from 'moment'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import App from './App.vue'

Vue.use(BootstrapVue)
Vue.prototype.moment = moment


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
