<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="col-xs-12 col-sm-12">
                <span class="txtwhite txt-footer"
                    >© {{ current_year }} B & S Florida Service, Inc. all rights
                    reserved. |
                    <a href="#" @click="$bvModal.show('priv-modal')">privacy</a>
                    |
                    <a href="#" @click="$bvModal.show('tos-modal')"
                        >terms of service</a
                    ></span
                >
            </div>
        </div>
    </footer>
</template>

<script>
    // import Privacy from './Privacy.vue'

    export default {
        name: "SiteFooter",
        data: function() {
            return {
                current_year: this.moment().format("YYYY")
            };
        },
        props: {},
        methods: {}
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
