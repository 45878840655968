<template>
    <b-modal id="tos-modal" title="Our Terms of Service" size="lg" ok-only>
        <div class="tos">
            <h5>1. Acceptance of our Terms</h5>
            <p>
                By visiting the website of B & S Florida Service, Inc., viewing,
                accessing or otherwise using any of the services or information
                created, collected, compiled or submitted to B & S Florida Service, Inc.,
                you agree to be bound by the following Terms and Conditions of
                Service. If you do not want to be bound by our Terms your only
                option is not to visit, view or otherwise use the services of
                B & S Florida Service, Inc.. You understand, agree and acknowledge that
                these Terms constitute a legally binding agreement between you
                and B & S Florida Service, Inc. and that your use of B & S Florida Service, Inc.
                shall indicate your conclusive acceptance of this agreement.
            </p>
            <h5>2. Provision of Services</h5>
            <p>
                You agree and acknowledge that B & S Florida Service, Inc. is entitled to
                modify, improve or discontinue any of its services at its sole
                discretion and without notice to you even if it may result in
                you being prevented from accessing any information contained in
                it. Furthermore, you agree and acknowledge that B&S Florida Service, Inc. is entitled to provide services to you through subsidiaries
                or affiliated entities.
            </p>
            <h5>3. Proprietary Rights</h5>
            <p>
                You acknowledge and agree that B & S Florida Service, Inc. may contain
                proprietary and confidential information including trademarks,
                service marks and patents protected by intellectual property
                laws and international intellectual property treaties. B & S Florida Service, Inc,
                authorizes you to view and make a single copy of
                portions of its content for offline, personal, non-commercial
                use. Our content may not be sold, reproduced, or distributed
                without our written permission. Any third-party trademarks,
                service marks and logos are the property of their respective
                owners. Any further rights not specifically granted herein are
                reserved.
            </p>
            <h5>4. Submitted Content</h5>
            <p>
                When you submit content to B & S Florida Service, Inc. you simultaneously
                grant B & S Florida Service, Inc. an irrevocable, worldwide, royalty free
                license to publish, display, modify, distribute and syndicate
                your content worldwide. You confirm and warrant that you have
                the required authority to grant the above license to B & S Florida Service, Inc.
            </p>
            <h5>5. Termination of Agreement</h5>
            <p>
                The Terms of this agreement will continue to apply in perpetuity
                until terminated by either party without notice at any time for
                any reason. Terms that are to continue in perpetuity shall be
                unaffected by the termination of this agreement.
            </p>
            <h5>6. Disclaimer of Warranties</h5>
            <p>
                You understand and agree that your use of B & S Florida Service, Inc. is
                entirely at your own risk and that our services are provided "As
                Is" and "As Available". B & S Florida Service, Inc. does not make any
                express or implied warranties, endorsements or representations
                whatsoever as to the operation of the B & S Florida Service, Inc.
                website, information, content, materials, or products. This
                shall include, but not be limited to, implied warranties of
                merchantability and fitness for a particular purpose and
                non-infringement, and warranties that access to or use of the
                service will be uninterrupted or error-free or that defects in
                the service will be corrected.
            </p>
            <h5>7. Limitation of Liability</h5>
            <p>
                You understand and agree that B & S Florida Service, Inc. and any of its
                subsidiaries or affiliates shall in no event be liable for any
                direct, indirect, incidental, consequential, or exemplary
                damages. This shall include, but not be limited to damages for
                loss of profits, business interruption, business reputation or
                goodwill, loss of programs or information or other intangible
                loss arising out of the use of or the inability to use the
                service, or information, or any permanent or temporary cessation
                of such service or access to information, or the deletion or
                corruption of any content or information, or the failure to
                store any content or information. The above limitation shall
                apply whether or not B & S Florida Service, Inc. has been advised of or
                should have been aware of the possibility of such damages. In
                jurisdictions where the exclusion or limitation of liability for
                consequential or incidental damages is not allowed the liability
                of B & S Florida Service, Inc. is limited to the greatest extent
                permitted by law. You agree and understand, that B&S Florida Service, Inc. is not liable for false information nor for missing
                information regarding its service.
            </p>
            <h5>8. External Content</h5>
            <p>
                B & S Florida Service, Inc. may include hyperlinks to third-party
                content, advertising or websites. You acknowledge and agree that
                B & S Florida Service, Inc. is not responsible for and does not endorse
                any advertising, products or resource available from such
                resources or websites.
            </p>

            <h5>9. Jurisdiction</h5>
            <p>
                You expressly understand and agree to submit to the personal and
                exclusive jurisdiction of the courts of the country, state,
                province or territory determined solely by B & S Florida Service, Inc. to
                resolve any legal matter arising from this agreement or related
                to your use of B & S Florida Service, Inc.. If the court of law having
                jurisdiction, rules that any provision of the agreement is
                invalid, then that provision will be removed from the Terms and
                the remaining Terms will continue to be valid.
            </p>
            <h5>10. Entire Agreement</h5>
            <p>
                You understand and agree that the above Terms constitute the
                entire general agreement between you and B & S Florida Service, Inc.. You
                may be subject to additional Terms and conditions when you use,
                purchase or access other services, affiliate services or
                third-party content or material.
            </p>
            <h5>11. Changes to the Terms</h5>
            <p>
                B & S Florida Service, Inc. reserves the right to modify these Terms from
                time to time at our sole discretion and without any notice.
                Changes to our Terms become effective on the date they are
                posted and your continued use of B & S Florida Service, Inc. after any
                changes to Terms will signify your agreement to be bound by
                them.
            </p>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "TOSRules",
        data: function() {
            return {
                langSelected: "en"
            };
        },
        props: {
            user_lang: String
        },
        methods: {
            selectLang: function(lang) {
                this.langSelected = lang;
                // $emit('selectLang(lang)')
            },
            track() {
                this.$gtag.pageview({
                    page_path: "/tos"
                });
            }            
        },
        mounted: function() {
            // component mounted
            // this.track()
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
