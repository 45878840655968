<template>
  <div id="app">
    <NavBar></NavBar>
    <HomeView></HomeView>
    <PrivacyRules></PrivacyRules>
    <TOSRules></TOSRules>
    <SiteFooter></SiteFooter>
  </div>
</template>


<script>
import HomeView from './components/HomePage.vue'
import NavBar from './components/NavBar.vue'
import SiteFooter from './components/SiteFooter.vue'
import PrivacyRules from './components/PrivacyRules.vue'
import TOSRules from './components/TOSRules.vue'


export default {
  name: 'App',
  components: {
    HomeView,
    NavBar,
    SiteFooter,
    PrivacyRules,
    TOSRules
  }
}
</script>


<style>
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5C5E63;
  background: #ffffff;
}
body {
min-height: 100vh;
position: relative;
margin: 0;
padding-bottom: 100px;
background: #ffffff;
} */

footer {
position: absolute;
bottom: 0;
width: 100%;
}
.pages {
  padding-top: 1rem;
  padding-right: 3rem;
  padding-left: 3rem;    
}
.footer {
  margin-top: 2rem;
  background: #555;
  color: #F1F1F1FF;
}
.center-text {
  text-align: center;
}
.main {
  margin-top: 96px;
}
.sidebar {
  margin-top: 1rem;
  padding-right: 2.5rem;
}
.side {
  border-bottom: 2px solid #f3966a;
  padding-top: 8px;
}
.side-top {
  padding-top: 0.5rem !important;
}
.side-photos1 {
  overflow-y: auto;
  height: 220px;
}
.right-icons {
  padding-right: 0.6rem;
  color: #e27550;
}
.pointer {
  cursor: pointer;
}
.smaller {
  font-size: 50%;
  font-weight: 200;
}
.bottombox {
  
}
.imomodal {
    margin-bottom: 4rem;
}
.smallprint {
  font-size: 11px;
  margin-top: -16px;
}

.secdeposit {
  background-color: #f0f8ff;
}

.pointer {
  cursor: pointer;
}
.txtblue {
  color: #326ABF;
}

.txtwhite {
  color: #FEFEFE;
}

.txtred {
  color: #CB0039;
}

.txtgreen {
  color: #007707;
}

.txtorange {
  color: #c4702b;
}

.alarmred {
  color: #ff6666;
}
.cal-input {
  background-clip: padding-box;
  background-color: #fff !important;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-sizing: border-box;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc((1.5em + .75rem) + 2px);
  line-height: 1.5;
  margin: 0;
  overflow: visible;
  padding: .375rem .75rem;
  transition-delay: 0s,0s,0s;
  transition-duration: .15s,.15s,.15s;
  transition-property: border-color,box-shadow,-webkit-box-shadow;
  transition-timing-function: ease-in-out,ease-in-out,ease-in-out;
  width: 100%;
}

/* Transition */
.fade-enter-active, .fade-leave-active {
transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}


.waitupload {
  padding-bottom: 2.5rem;
  color: #ff9107;
}  

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #FFDE00;
  margin: 80px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.header-top {
  margin-top: 1.5rem;
}
.lead-pad {
  padding-right: 3rem;
}
.about-header {
  font-size: 2.5rem;
}
.about-body {
  font-size: 1.1rem;
}
.pb1rem {
  padding-bottom: 1rem;
}
.card-img {
  width: 74%!important;
  margin: 0.5rem;
}
.font-serif {
  font-family: serif;
}






#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5C5E63;
  background: #ffffff;
}
body {
min-height: 100vh;
position: relative;
margin: 0;
padding-bottom: 100px;
background: #ffffff;
}

footer {
position: absolute;
bottom: 0;
width: 100%;
}
.pages {
  padding-top: 1rem;
  padding-right: 3rem;
  padding-left: 3rem;    
}
.footer {
  margin-top: 2rem;
  background: #555;
  color: #F1F1F1FF;
}
.center-text {
  text-align: center;
}
.main {
  margin-top: 96px;
}
.sidebar {
  margin-top: 1rem;
  padding-right: 2.5rem;
}
.side {
  border-bottom: 2px solid #f3966a;
  padding-top: 8px;
}
.side-top {
  padding-top: 0.5rem !important;
}
.side-photos1 {
  overflow-y: auto;
  height: 220px;
}
.right-icons {
  padding-right: 0.6rem;
  color: #e27550;
}
.pointer {
  cursor: pointer;
}
.smaller {
  font-size: 50%;
  font-weight: 200;
}

.imomodal {
    margin-bottom: 4rem;
}
.smallprint {
  font-size: 11px;
  margin-top: -16px;
}

.secdeposit {
  background-color: #f0f8ff;
}

.pointer {
  cursor: pointer;
}
.txtblue {
  color: #326ABF;
}

.txtwhite {
  color: #FEFEFE;
}

.txtred {
  color: #CB0039;
}

.txtgreen {
  color: #007707;
}

.txtorange {
  color: #c4702b;
}

.alarmred {
  color: #ff6666;
}
.cal-input {
  background-clip: padding-box;
  background-color: #fff !important;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-sizing: border-box;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc((1.5em + .75rem) + 2px);
  line-height: 1.5;
  margin: 0;
  overflow: visible;
  padding: .375rem .75rem;
  transition-delay: 0s,0s,0s;
  transition-duration: .15s,.15s,.15s;
  transition-property: border-color,box-shadow,-webkit-box-shadow;
  transition-timing-function: ease-in-out,ease-in-out,ease-in-out;
  width: 100%;
}

/* Transition */
.fade-enter-active, .fade-leave-active {
transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}


.waitupload {
  padding-bottom: 2.5rem;
  color: #ff9107;
}  

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #FFDE00;
  margin: 80px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.header-top {
  margin-top: 1.5rem;
}
.lead-pad {
  padding-right: 3rem;
}
.about-header {
  font-size: 2.5rem;
}
.about-body {
  font-size: 1.1rem;
}
.pb1rem {
  padding-bottom: 1rem;
}
.card-img {
  width: 74%!important;
  margin: 0.5rem;
}
.font-serif {
  font-family: serif;
}
</style>


