<template>
    <div>
        <b-carousel id="carousel-1" v-model="slidenumber" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="280" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">

            <div v-for="c in sli.slides" :key="c.number">
                <b-carousel-slide :img-src="c.imgsrc" :img-alt="c.imgalt">
                    <h1>{{ c.message }}</h1>
                </b-carousel-slide>
            </div>

        </b-carousel>

    </div>
</template>



<script>
export default {
    name: 'SliderFotos',
    data() {
        return {
            slidenumber: 0,
            sliding: null,
            useSliders: [],

            photos: [
                { imgsrc: require("@/assets/slider/sli17.jpg") },
                { imgsrc: require("@/assets/slider/sli12.jpg") },
                { imgsrc: require("@/assets/slider/sli11.jpg") },
                { imgsrc: require("@/assets/slider/sli14.jpg") },
                { imgsrc: require("@/assets/slider/sli13.jpg") },
                { imgsrc: require("@/assets/slider/sli18.jpg") },
                { imgsrc: require("@/assets/slider/sli10.jpg") },
                { imgsrc: require("@/assets/slider/sli16.jpg") },
                { imgsrc: require("@/assets/slider/sli15.jpg") },
            ],

            sliders: {
                en: {
                    slides: [
                        {
                            number: 1,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "Just a few.."
                        },
                        {
                            number: 2,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "... of the wonderful..."
                        },
                        {
                            number: 3,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "... Luxury-Villas..."
                        },
                        {
                            number: 4,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "... in Cape Coral ..."
                        },
                        {
                            number: 5,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "... our valued customers ..."
                        },
                        {
                            number: 6,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "... asked us to ..."
                        },
                        {
                            number: 7,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "... take care of."
                        },
                        {
                            number: 8,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: ""
                        },
                        {
                            number: 9,
                            imgsrc: "",
                            imgalt: "a 1st Place Property Group managed villa.",
                            message: "Thank You!"
                        }
                    ]
                },
                de: {
                    slides: [
                        {
                            number: 1,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "Ein paar..."
                        },
                        {
                            number: 2,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "... ausgewählte ..."
                        },
                        {
                            number: 3,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "... Luxus-Immobilien..."
                        },
                        {
                            number: 4,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "...in Cape Coral, Florida..."
                        },
                        {
                            number: 5,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "...mit deren Betreuung wir..."
                        },
                        {
                            number: 6,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "...von unseren internationalen Kunden..."
                        },
                        {
                            number: 7,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "...beauftragt worden sind."
                        },
                        {
                            number: 8,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: ""
                        },
                        {
                            number: 9,
                            imgsrc: "",
                            imgalt: "1st Place Property Group verwaltete Luxus-Villa.",
                            message: "Danke!!!"
                        }
                    ]
                }
            }
        }
    },
    props: {
        user_lang: String,
        deutsch: Boolean
    },
    computed: {

        sli() {
            return this.sliders[this.user_lang];
        }
    },
    methods: {

        shuffleArray() {
            this.photos.sort(() => Math.random() - 0.5);
        },
        stuffPhotos(array) {
            // console.log(array);
            for (let index = 0; index < array.length; index++) {
                const imgsource = array[index].imgsrc;
                this.useSliders[index].imgsrc = imgsource;
            }
            return this.sli
        },

        getLanguageSlides() {
            // 1. shuffle the photos array
            // 2. get the language adjusted sliders dictionary and create the useSliders dictionary
            // 3. add the imgsrc to the new dictionary.
            for (let index = 0; index < this.sliders[this.user_lang].slides.length; index++) {
                const _slide = this.sliders[this.user_lang].slides[index];
                this.useSliders.push(_slide)
            }
            this.stuffPhotos(this.photos);
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        }
    },
    created: function () {
        this.shuffleArray();
        
    },
    mounted: function () {

        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            this.getLanguageSlides();
        })
    }
}
</script>