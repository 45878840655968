<template>
    <b-modal id="priv-modal" title="Privacy" size="lg" ok-only>
        <h5>What information do we collect?</h5>

        <p>
            We collect information from you when you register on our site,
            subscribe to our newsletter, respond to a survey or fill out a form.
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your: name, e-mail address, mailing address or phone
            number. You may, however, visit our site anonymously.
        </p>
        <h5>What do we use your information for?</h5>

        <p>
            Any of the information we collect from you may be used in one of the
            following ways:
        </p>
        <ul class="modalList">
            <li>
                To personalize your experience
                <small
                    >(your information helps us to better respond to your
                    individual needs)</small
                >
            </li>
            <li>
                To improve our website
                <small
                    >(we continually strive to improve our website offerings
                    based on the information and feedback we receive from
                    you)</small
                >
            </li>
            <li>
                To improve customer service
                <small
                    >(your information helps us to more effectively respond to
                    your customer service requests and support needs)</small
                >
            </li>
            <li>
                To administer a contest, promotion, survey or other site feature
            </li>
            <li>To send periodic emails</li>
        </ul>
        <p>
            The email address you provide for order processing, may be used to
            send you information and updates pertaining to your order, in
            addition to receiving occasional company news, updates, related
            product or service information, etc. Note: If at any time you would
            like to unsubscribe from receiving future emails, we include
            detailed unsubscribe instructions at the bottom of each email.
        </p>
        <h5>How do we protect your information?</h5>
        <p>
            We implement a variety of security measures to maintain the safety
            of your personal information when you enter, submit, or access your
            personal information.
        </p>
        <h5>Do we use cookies?</h5>
        <p>
            Yes. Cookies are small files that a site or its service provider
            transfers to your computers hard drive through your Web browser (if
            you allow) that enables the sites or service providers systems to
            recognize your browser and capture and remember certain information
            We use cookies to understand and save your preferences for future
            visits.
        </p>
        <h5>Do we disclose any information to outside parties?</h5>
        <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other uses.
        </p>
        <h5>Third party links</h5>
        <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
        </p>
        <h5>California Online Privacy Protection Act Compliance</h5>
        <p>
            Because we value your privacy we have taken the necessary
            precautions to be in compliance with the California Online Privacy
            Protection Act. We therefore will not distribute your personal
            information to outside parties without your consent.
        </p>
        <h5>Online Privacy Policy Only</h5>
        <p>
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
        </p>
        <h5>Your Consent</h5>
        <p>
            By using our site, you consent to our websites privacy policy.
        </p>
        <h5>Changes to our Privacy Policy</h5>
        <p>
            If we decide to change our privacy policy, we will post those
            changes on this page. This policy is powered by Trust Guard PCI
            compliance.
        </p>
    </b-modal>
</template>

<script>
    export default {
        name: "PrivacyRules",
        data: function() {
            return {
                langSelected: "en"
            };
        },
        props: {
            user_lang: String
        },
        methods: {
            selectLang: function(lang) {
                this.langSelected = lang;
                // $emit('selectLang(lang)')
            },
            track() {
                this.$gtag.pageview({
                    page_path: "/privacy"
                });
            }            
        },
        mounted: function() {
            // component mounted
            // this.track()
        }
            
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
