<template>
    <div>
        <b-navbar toggleable="lg" class="navi" fixed="top">
            <b-navbar-brand href="#">
                <!-- <img src="../assets/logo.png" alt="1st Place Property" class="nav-logo" /> -->
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item to="/">Home</b-nav-item>
                    <!-- <b-nav-item to="/about">{{ $t("nav.about") }}</b-nav-item> -->
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ms-auto">
                    <div>
                        <h5 class="contact-nav">
                            Call or Text us: (239) 878-2771
                        </h5>
                    </div>

                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data: function () {
        return {
            test: '...what???',
            langSelected: 'en',
        }
    },
    props: {
        user_lang: String,
        deutsch: Boolean,
        // sticky: Boolean
    },
    methods: {
        selectLang: (function (lang) {
            this.langSelected = lang;
            // $emit('selectLang(lang)')
            this.$emit('changeLang', lang)
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navi {
    background-color: #ffffff;
}

.nav-logo {
    height: 50px;
}

.router-link-exact-active {
    color: red !important;
}

.flag {
    cursor: pointer;
}

.contact-nav {
    margin-right: 2rem;
    margin-top: 0.2rem;
}
</style>
