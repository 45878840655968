<template>
  <div class="home">
    <b-container class="main">
      <b-row>
        <b-col>
          <img class="hplogo img-fluid"  alt="B&S Florida Service, Inc. logo" src="@/assets/logo.png"/>
        </b-col>
      </b-row>
      <SliderFotos :user_lang="user_lang"></SliderFotos>
      <h1 class="header-top">{{ content.leadHeader }}</h1>


      <b-row>
        <b-col>
          <p class="lead">{{ content.leadBody }}</p>
        </b-col>
        <b-col>
          <p class="lead">{{ content.leadBody2 }}</p>
        </b-col>
      </b-row>

      <div>
        <hr class="hr hr-blurry pb1rem" />
      </div>



      <b-row>
        <div v-for="c in content.columns" :key="c.number" class="mb-2">
          <b-card no-body class="overflow-hidden" style="max-width: 100%;">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-img :src="c.imgsrc" alt="c.imgalt" class="card-img rounded-0"></b-card-img>
              </b-col>
              <b-col md="6">
                <b-card-body class="biggerheader" :title="c.title">
                  <b-card-text class="biggerfont">
                    {{ c.body }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-row>

    </b-container>
  </div>
</template>

<script>

// const options = {
//     transformAssetUrls: {
//       video: ['src', 'poster'],
//       source: 'src',
//       img: 'src',
//       image: 'xlink:href',
//       'b-avatar': 'src',
//       'b-img': 'src',
//       'b-img-lazy': ['src', 'blank-src'],
//       'b-card': 'img-src',
//       'b-card-img': 'src',
//       'b-card-img-lazy': ['src', 'blank-src'],
//       'b-carousel-slide': 'img-src',
//       'b-embed': 'src'
//     }
//   }

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import SliderFotos from '../components/SliderFotos.vue'
export default {
  name: "HomeView",
  components: {
    SliderFotos
  },
  data: function () {
    return {
      // user_lang: "en",
      contentbase: {
        en: {
          leadHeader: "OUR HOME WATCH SERVICE",
          leadBody:
            "Welcome to B&S Florida Service, where we specialize in providing top-notch homewatch services for luxury vacation rental villas in Cape Coral, Florida. Our team of experienced professionals are dedicated to ensuring that your property is well taken care of while you're away, giving you peace of mind and the assurance that your investment is in good hands. Our services include regular property inspections, emergency response, and preventative maintenance to keep your villa in top condition. We also offer concierge services, such as arranging for housekeeping, pool maintenance, and landscaping, to ensure that your property is ready for your next guests.",
          leadBody2:
            "We understand the importance of maintaining the value of your property, which is why our team is dedicated to providing the highest level of service to our clients. We take pride in our attention to detail, and strive to exceed your expectations.If you're interested in learning more about our services and available properties, please don't hesitate to contact us. We look forward to serving you and making your Cape Coral vacation rental experience a memorable one.",
          columns: [
            {
              number: 1,
              title: "Why Home Watch",
              imgsrc: require("@/assets/water_leak_s.jpg"),
              imgalt: "Water leaking",
              body:
                "Can’t my house just watch itself while I’m away? Things like pool pumps, air conditioning, irrigation systems have timers and run by themselves, don’t they? Yes, you are correct: they do, but…. Say the water level in your pool drops too much, then your pool pump will run dry and die. If your air condition is not running (say a breaker in your panel flipped), mold could grow quickly. Leaking water pipes running for months… you get the idea! 1st Place Home Watch weekly inspections ensure nothing gets out of hand."
            },
            {
              number: 2,
              title: "Peace of Mind",
              imgsrc: require("@/assets/house5_sm.jpg"),
              imgalt: "house",
              body:
                "Check out our home watch Gold services. We are committed to delivering the best service and peace of mind while you are away. Whether you own a vacation home or a second residence in Cape Coral, we will take care of all your needs. B&S Florida Service Group is licensed and insured and our staff is committed, service-oriented, and has a thorough knowledge of all property management questions."
            },
            {
              number: 3,
              title: "Vacation Rental Service",
              imgsrc: require("@/assets/concierge.jpg"),
              imgalt: "concierge",
              body:
                "We have a strong relationship with our customers when it comes to them renting out their homes. We take care of their vacation rental guest in the best way imaginable. With our check-ins we introduce the guests to their vacation rental including how the technical aspects work - think pool/spa system, pool heater, A/C, TV’s and BBQ. During their stay we are available when they have questions or need help. We perform a personal check-out at the end of their vacation and inspect the house for any damages. We then coordinate the cleaning teams and make sure, that the house is in a pristine condition again. We accustomed many guest who make sure, their next vacation is in a house which we manage. We are very proud of this."
            },
            {
              number: 4,
              title: "Hi-Tech for You",
              imgsrc: require("@/assets/hight-tech-girl_s.jpg"),
              imgalt: "tech-girl",
              body:
                "Our staff is always informed about any settings or information concerning your home. Weekly check-ups are documented in real-time and stored securely.  All documents and receipts are electronically filed in our encrypted database and are available to you.  We don’t have piles of loose papers. We have an organized online system. That way we improve communication and make sure that the best care is given to your home."
            }
          ]
        },

        de: {
          leadHeader: "UNSER HAUS SERVICE",
          leadBody:
            "B&S Florida Service provides high-quality, committed, secure and reliable home watch services for your house or condo in Cape Coral while you are away. If you own a home and you are absent, we will take care of all your needs to give you peace of mind. Our professional services focuses on client satisfaction and includes home watch and concierge services. Many insurance companies offer discounts if your home or condo is regularly monitored.",
          columns: [
            {
              number: 1,
              title: "Why Home Watch",
              imgsrc: require("@/assets/water_leak_s.jpg"),
              imgalt: "Water leaking",
              body:
                "'Can’t my house just watch itself while I’m away? Things like pool pumps, air conditioning, irrigation systems have timers and run by themselves, don’t they?'<br>Yes, you are correct: they do, but…. Say the water level in your pool drops too much, then your pool pump will run dry and die. If your air condition is not running (say a breaker in your panel flipped), mold could grow quickly. Leaking water pipes running for months… you get the idea! 1st Place Home Watch weekly inspections ensures nothing gets out of hand."
            },
            {
              number: 2,
              title: "Peace of Mind",
              imgsrc: require("@/assets/house5_sm.jpg"),
              imgalt: "",
              body:
                "Check out our home watch Gold services here. We are committed to delivering the best service and peace of mind while you are away. Wether you own a vacation home or a second residence in Cape Coral, we will take care of all your needs. B&S Florida Service Group is licensed and insured and our staff is committed, service oriented and has a thorough knowledge of all property management questions."
            },
            {
              number: 3,
              title: "Concierge Service",
              imgsrc: require("@/assets/concierge.jpg"),
              imgalt: "",
              body:
                "Our outstanding concierge service takes care of all your needs, from shopping to car care to individual airport pick-up and drop-off. Your convenience is our primary concern. We can have everything prepared for your arrival including your own car waiting at the airport, or welcome your vacation guests with a welcome package and make them feel at home from day one."
            },
            {
              number: 4,
              title: "Hi-Tech for You",
              imgsrc: require("@/assets/hight-tech-girl_s.jpg"),
              imgalt: "",
              body:
                "Our staff is always informed about any settings or information concerning your home. Weekly check-ups are documented in real-time and stored securely.  All documents and receipts are electronically filed in our encrypted database and are available to you.  We don’t have piles of loose papers. We have an organized online system. That way we improve communication and make sure that the best care is given to your home."
            }
          ]
        }
      }
    };
  },
  computed: {
    user_lang() {
      return "en";
    },
    content() {
      return this.contentbase[this.user_lang];
    }
  },
};
</script>

<style>
.hplogo {
  padding-bottom: 10px;
}

.biggerfont {
  font-size: larger;
}

h4 {
  font-size: 1.7rem;
}
</style>